<script setup lang="ts">

import {tabMoon, tabSun} from 'quasar-extras-svg-icons/tabler-icons-v2'

const $q = useQuasar()

const darkModeDefault = localStorage.getItem('darkMode') ?? 'auto'

onMounted(() => {
  nextTick(() => {
    if (darkModeDefault === 'true') {
      $q.dark.set(true)
    } else if (darkModeDefault === 'false') {
      $q.dark.set(false)
    } else {
      $q.dark.set('auto')
    }
  })
})

function setDarkMode(value: boolean | 'auto') {
  nextTick(() => {
    $q.dark.set(value)
    localStorage.setItem('darkMode', value.toString())
  })
}
</script>

<template>
  <QBtnDropdown :icon="$q.dark.isActive ? tabMoon : tabSun" auto-close>
    <QList>
      <QItem v-if="!$q.dark.isActive" v-close-popup clickable @click="setDarkMode(true)">
        <QItemSection>
          <QItemLabel>{{ $t('Dark mode') }}</QItemLabel>
        </QItemSection>
      </QItem>
      <q-item v-if="$q.dark.isActive" v-close-popup clickable @click="setDarkMode(false)">
        <q-item-section>
          <q-item-label>{{ $t('Light mode') }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item v-close-popup clickable @click="setDarkMode('auto')">
        <q-item-section>
          <q-item-label>{{ $t('System') }}</q-item-label>
        </q-item-section>
      </q-item>
    </QList>
  </QBtnDropdown>
</template>
